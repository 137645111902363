import React from "react";
import SigninPage from "./pages/signin";
import Info from "./pages/info";
import Reference from "./pages/references";
import Contact from "./pages/contact";
import Home from "./pages";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { inject } from "@vercel/analytics";

export default function App() {
  inject();

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/signin" element={<SigninPage />} exact />
        <Route path="/info" element={<Info />} exact />
        <Route path="/referenzen" element={<Reference />} exact />
        <Route path="/kontakt" element={<Contact />} exact />
      </Routes>
    </BrowserRouter>
  );
}
