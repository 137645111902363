import React, { useState } from "react";
import ImpressumModal from "./ImpressumModal";
import {
  FooterContainer,
  FooterWrap,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  FooterLinksContainer,
  SocialIcons,
  SocialIconLink,
  SocialLogo,
  SocialMedia,
  SocialMediaWrap,
  WebsiteRights,
} from "./FooterElements";

import { FaInstagram } from "react-icons/fa";
import Image from "../../images/Logo.png";

const Footer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>S. Autopflege</FooterLinkTitle>
              <FooterLink to="home" href="#home" smooth={true} duration={500}>
                Home
              </FooterLink>
              <FooterLink to="info" href="#info" smooth={true} duration={500}>
                Info
              </FooterLink>
              <FooterLink
                to="referenzen"
                href="#referenzen"
                smooth={true}
                duration={500}
              >
                Portfolio
              </FooterLink>
              <FooterLink
                to="kontakt"
                href="#kontakt"
                smooth={true}
                duration={500}
              >
                Kontakt
              </FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>
                <FooterLink as="div" href="#impressum" onClick={toggleModal}>
                  Impressum
                </FooterLink>
              </FooterLinkTitle>
              <FooterLink as="a" href="tel:+491743196819">
                +49 (0) 174 319 6819
              </FooterLink>
              <FooterLink
                href="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1717.9479379259176!2d6.991377!3d50.869895!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bf215478ce0dad%3A0x9b18b33bd414a483!2sSchr%C3%B6ders%20Autopflege!5e1!3m2!1sde!2sde!4v1731501860571!5m2!1sde!2sde"
                target="_blank"
              >
                Kelvinstr. 33
              </FooterLink>
              <FooterLink
                href="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1717.9479379259176!2d6.991377!3d50.869895!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bf215478ce0dad%3A0x9b18b33bd414a483!2sSchr%C3%B6ders%20Autopflege!5e1!3m2!1sde!2sde!4v1731501860571!5m2!1sde!2sde"
                target="_blank"
              >
                Halle 2
              </FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="home" href="#home" smooth={true} duration={500}>
              <img
                src={Image}
                alt="Logo"
                style={{ height: "40px", width: "auto" }}
              />
            </SocialLogo>
            <WebsiteRights>© 2024</WebsiteRights>
            <SocialIcons>
              <SocialIconLink
                href="https://www.instagram.com/schroeders.autopflege/"
                target="_blank"
              >
                <FaInstagram />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
      {isModalOpen && <ImpressumModal closeModal={toggleModal} />}
    </FooterContainer>
  );
};

export default Footer;
