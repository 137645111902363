import React, { useState } from "react";
import styled from "styled-components";

const CookieBannerContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 9999;
`;

const CookieText = styled.p`
  max-width: 90%;
  font-size: 14px;
  line-height: 1.4;
  margin: 0 auto 10px; /* Sorgt für gleiche Abstände links und rechts */
  text-align: center;
  
  @media (min-width: 768px) {
    max-width: 600px; /* Begrenzte Breite für größere Bildschirme */
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  width: 100%;
  padding: 0 15px; /* Stellt sicher, dass Buttons nicht am Rand kleben */
`;

const Button = styled.button`
  background: ${({ primary, disabled }) =>
    disabled ? "#444" : primary ? "#dac67c" : "#555"};
  color: #fff;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  border-radius: 5px;
  transition: background 0.3s ease;
  opacity: ${({ disabled }) => (disabled ? "0.6" : "1")};

  &:hover {
    background: ${({ primary, disabled }) =>
      disabled ? "#444" : primary ? "#b89f5e" : "#777"};
  }

  @media (max-width: 480px) {
    font-size: 12px;
    padding: 6px 10px;
  }
`;

const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(true);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "accepted");
    setShowBanner(false);
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "declined");
    setShowBanner(false);
  };

  return (
    <CookieBannerContainer show={showBanner}>
      <CookieText>
        Wir verwenden Cookies, um Ihnen ein optimales Website-Erlebnis zu bieten. Dazu gehören essenzielle Cookies, 
        die für den Betrieb der Seite notwendig sind, sowie optionale Cookies für Statistik- und Marketingzwecke. 
        Sie können Ihre Einwilligung jederzeit in den Cookie-Einstellungen ändern. Weitere Informationen finden Sie in unserer 
        <span style={{ color: "#999", textDecoration: "underline", cursor: "default" }}> Datenschutzerklärung</span>.
      </CookieText>
      <ButtonContainer>
        <Button primary onClick={handleAccept}>Alle akzeptieren</Button>
        <Button onClick={handleDecline}>Nur essenzielle Cookies</Button>
        <Button disabled>Einstellungen verwalten</Button>
      </ButtonContainer>
    </CookieBannerContainer>
  );
};

export default CookieConsent;
