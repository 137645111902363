import React, { useState, useEffect } from "react";
import { Button } from "../ButtonElements";
import { AddressMap } from "./Map";
import Modal from "../Contact/Modal";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
  MapWrapper,
} from "./InfoSectionElements";

const InfoSection = ({
  lightBg,
  id,
  imgStart,
  topLine,
  headLine,
  description,
  lightText,
  darkText,
  buttonLabel,
  phone,
  img,
  alt,
  primary,
  dark,
  dark2,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  return (
    <InfoContainer lightBg={lightBg} id={id}>
      <InfoWrapper>
        <InfoRow imgStart={imgStart}>
          <Column1>
            <TextWrapper>
              <TopLine>{topLine}</TopLine>
              <Heading lightText={lightText}>{headLine}</Heading>
              <Subtitle darkText={darkText}>{description}</Subtitle>
              <BtnWrap>
                <Button
                  to="termin"
                  href="#termin"
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="ture"
                  offset={-80}
                  primary={primary ? 1 : 0}
                  dark={dark ? 1 : 0}
                  dark2={dark2 ? 1 : 0}
                  onClick={openModal}
                >
                  {buttonLabel}
                </Button>
              </BtnWrap>
            </TextWrapper>
          </Column1>
          <Column2>
            <ImgWrap>
              <Img src={img} alt={alt} />
              {/* <AddressMap /> */}
            </ImgWrap>
          </Column2>
        </InfoRow>
      </InfoWrapper>
              {/* Modal */}
              {isModalOpen && <Modal closeModal={closeModal} />}
    </InfoContainer>
    
  );
};
export default InfoSection;
