export const homeObjOne = {
  id: "info",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: " Qualität die man sieht",
  headLine: "Perfektion im Detail",
  description:
    "Wir sind Ihr Partner für professionelle Autoaufbereitung und -pflege auf höchstem Niveau in Köln Rodenkirchen. Mit Präzision und Liebe zum Detail sorgen wir dafür, dass Ihr Fahrzeug innen wie außen strahlt. Ob Keramikversiegelung, Handwäsche, spezielle Leasing- oder Lederaufbereitung – wir bieten das Rundum-Paket, um den Wert und die Schönheit Ihres Fahrzeugs dauerhaft zu erhalten oder wiederherzustellen.",
  buttonLabel: "Termin vereinbaren",
  imgStart: false,
  //Bild von undraw.co illustrationen raussuchen
  img: require("../../images/Info.jpg"),
  alt: "InfoImage",
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjTwo = {
  id: "kontakt",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Schröders Autopflege",
  headLine: "Kontakt ",
  description: "Kelvin Str. 33 \n Halle 2 \n 50996 Köln",
  phone:"+49 (0) 174 319 6819",
  buttonLabel: "Termin vereinbaren",
  imgStart: true,
  //Bild von undraw.co illustrationen raussuchen
  img: require("../../images/HeroBackground.jpg"),
  alt: "InfoImage",
  dark: false,
  primary: false,
  darkText: true,
};


