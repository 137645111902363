import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";
import { useLocation } from "react-router-dom";
import Modal from "../Contact/Modal";

import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinkScroll,
  NavLinkRouter,
  NavBtn,
  NavBtnLink,
} from "./NavbarElements";

import Image from "../../images/Logo.png";

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation(); // Aktuelle Route abrufen

  const changeNav = () => {
    if (window.scrollY >= 80 || location.pathname !== "/") {
      setScrollNav(true); // Navbar mit Hintergrund, wenn nicht auf "/"
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    changeNav(); // Direkt prüfen
    window.addEventListener("scroll", changeNav);
    return () => window.removeEventListener("scroll", changeNav);
  }, [location.pathname]); // Aktualisieren bei Routenwechsel

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav scrollNav={scrollNav}>
          <NavbarContainer>
            <NavLogo to="/" href="#home" onClick={toggleHome}>
              <img
                src={Image}
                alt="Logo"
                style={{ height: "40px", width: "auto" }}
              />
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars color="#dac67c" />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                {location.pathname === "/" ? (
                  <NavLinkScroll
                    to="home"
                    href="#home"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                  >
                    Home
                  </NavLinkScroll>
                ) : (
                  <NavLinkRouter to="/" href="#home">
                    Home
                  </NavLinkRouter>
                )}
              </NavItem>
              <NavItem>
                {location.pathname === "/" ? (
                  <NavLinkScroll
                    to="info"
                    href="#info"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                  >
                    Info
                  </NavLinkScroll>
                ) : (
                  <NavLinkRouter to="/info" href="#info">
                    Info
                  </NavLinkRouter>
                )}
              </NavItem>
              <NavItem>
                {location.pathname === "/" ? (
                  <NavLinkScroll
                    to="referenzen"
                    href="#referenzen"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-100}
                  >
                    Portfolio
                  </NavLinkScroll>
                ) : (
                  <NavLinkRouter to="/#referenzen" href="#referenzen">
                    Portfolio
                  </NavLinkRouter>
                )}
              </NavItem>
              <NavItem>
                {location.pathname === "/" ? (
                  <NavLinkScroll
                    to="kontakt"
                    href="#kontakt"
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact="true"
                    offset={-80}
                  >
                    Kontakt
                  </NavLinkScroll>
                ) : (
                  <NavLinkRouter to="/#kontakt" href="#kontakt">
                    Kontakt
                  </NavLinkRouter>
                )}
              </NavItem>
            </NavMenu>
            <NavBtn>
              <NavBtnLink onClick={openModal}>Termin</NavBtnLink>
            </NavBtn>
          </NavbarContainer>
        </Nav>
        {isModalOpen && <Modal closeModal={closeModal} />}
      </IconContext.Provider>
    </>
  );
};

export default Navbar;
