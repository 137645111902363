import React from "react";
import styled from "styled-components";

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #ffffff; /* Setze den Hintergrund des Modals auf Weiß */
  color: #010606; /* Definiere die Textfarbe */
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
 
`;

const ModalCloseButton = styled.button`
  margin-top: 20px;
  background: #dac67c;
  color: #010606;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background: #b59c63;
  }
`;

const Subtitle = styled.p`
 white-space: pre-line;
`;

const Modal = ({ closeModal }) => {
  return (
    <ModalOverlay>
      <ModalContent>
        <h2>Hinweis</h2>
        <p>&nbsp;</p>
        <Subtitle>
          Die Möglichkeit, Termine online zu vereinbaren, steht derzeit noch
          nicht zur Verfügung. {"\n"}
          </Subtitle>
          <p>&nbsp;</p>
          <Subtitle>
          Bitte kontaktieren Sie uns telefonisch unter: {"\n"}
          </Subtitle>
          <p>&nbsp;</p>
          <Subtitle>
  <strong>
    <a href="tel:+491743196819" style={{ color: "#010606", textDecoration: "none" }}>
    +49 (0) 174 319 6819
    </a>
  </strong>
</Subtitle>
        <ModalCloseButton onClick={closeModal}>Schließen</ModalCloseButton>
      </ModalContent>
    </ModalOverlay>
  );
};


export default Modal;
