import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SocialIcons,
  SocialIconLink,
  SidebarRoute,
} from "./SidebarElements";
import { FaInstagram } from "react-icons/fa";
import { useLocation } from "react-router-dom"; // useLocation für aktuelle Route
import { Link as LinkS } from "react-scroll"; // scroll links
import { Link as LinkR } from "react-router-dom"; // react-router links

const Sidebar = ({ isOpen, toggle }) => {
  const location = useLocation(); // Aktuelle Route abrufen

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon color="#dac67c" />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          {/* Home Link - für die Hauptseite verwenden wir LinkS für das Scrollen */}
          {location.pathname === "/" ? (
            <SidebarLink
              to="home"
              href="#home"
              smooth={true}
              duration={500}
              spy={true}
              offset={-80}
              onClick={toggle}
            >
              Home
            </SidebarLink>
          ) : (
            <SidebarRoute to="/" href="#home" onClick={toggle}>
              Home
            </SidebarRoute>
          )}

          {/* Info Link - wenn wir auf der Hauptseite sind, verwenden wir LinkS, ansonsten LinkR */}

          {location.pathname === "/" ? (
            <SidebarLink
              to="info"
              href="#info"
              smooth={true}
              duration={500}
              spy={true}
              offset={-80}
              onClick={toggle}
            >
              Info
            </SidebarLink>
          ) : (
            <SidebarRoute to="/info" href="#info" onClick={toggle}>
              Info
            </SidebarRoute>
          )}

          {/* Portfolio Link - wieder LinkS für Hauptseite und LinkR für andere Seiten */}

          {location.pathname === "/" ? (
            <SidebarLink
              to="referenzen"
              href="#referenzen"
              smooth={true}
              duration={500}
              spy={true}
              offset={-80}
              onClick={toggle}
            >
              Portfolio
            </SidebarLink>
          ) : (
            <SidebarRoute to="/" href="#referenzen" onClick={toggle}>
              Portfolio
            </SidebarRoute>
          )}

          {/* Kontakt Link - auch hier LinkS für Hauptseite und LinkR für andere Seiten */}

          {location.pathname === "/" ? (
            <SidebarLink
              to="kontakt"
              href="#kontakt"
              smooth={true}
              duration={500}
              spy={true}
              offset={-80}
              onClick={toggle}
            >
              Kontakt
            </SidebarLink>
          ) : (
            <SidebarRoute to="/" href="#kontakt" onClick={toggle}>
              Kontakt
            </SidebarRoute>
          )}
        </SidebarMenu>

        {/* Social Media Links */}
        <SocialIcons>
          <SocialIconLink
            href="https://www.instagram.com/schroeders.autopflege/"
            target="_blank"
          >
            <FaInstagram />
          </SocialIconLink>
        </SocialIcons>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
