import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import InfoSection from "../components/InfoSection";
import Footer from "../components/Footer";
import "../styles.css";
import { homeObjOne } from "../components/InfoSection/Data";

const Info = () => {
  const [isOpen, setisOpen] = useState(false);

  const toggle = () => {
    setisOpen(!isOpen);
  };

  // Titel beim Laden der Seite setzen
  useEffect(() => {
    document.title =
      "Schröders Autopflege | Autoaufbereitung Köln | Kfz-Aufbereitung & Keramikversiegelung Köln"; // Setze den gewünschten Titel

      const canonicalUrl = window.location.origin + window.location.pathname;
      let link = document.querySelector("link[rel='canonical']");
  
      if (!link) {
        link = document.createElement("link");
        link.setAttribute("rel", "canonical");
        document.head.appendChild(link);
      }
      
      link.setAttribute("href", canonicalUrl);

    return () => {
      document.title =
        "Schröders Autopflege | Autoaufbereitung Köln | Kfz-Aufbereitung & Keramikversiegelung Köln Rodenkirchen | Keramikversiegelung Köln"; // Optional: Setzt den Titel zurück, wenn die Seite verlassen wird
        if (link) {
          link.remove(); // Entfernt den Canonical-Link beim Verlassen der Seite
        }
      };
  }, []);

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <InfoSection {...homeObjOne} />
      <Footer />
    </>
  );
};

export default Info;
